import Map from './contact-us-components/Map';

function ContactUs() {
    return (
        <>
            // TODO create a map view, a availability chart and maybe a contact us form?
            <h1>Coming Soon</h1>
            <Map />
        </>
    );
}

export default ContactUs