// import Banner from './about-us-components/AboutUsBanner'
import TestBox from './about-us-components/TestBox';
import AboutUsNav from './about-us-components/AboutUsNav'
import './CSS_components/about-us.css'

function AboutUs() {
    return (
        // <Banner />
        // Introduction to retail
        // History
        // Commitment to quality
        // our store location
        // TODO add about us content
        // <h1>Coming Soon</h1>
        <div className='aboutUsPage'>
            <AboutUsNav />
            <TestBox />
            <div className='theBigTestDiv'></div>
        </div>
    );
}

export default AboutUs