
const AboutUsNav = () => (
    <div className="aboutUsNav">
        <ul>
            <li>About Us</li>
            <li>Other Tabs</li>
        </ul>
    </div>
)

export default AboutUsNav